
import React from "react";

export const ptBr = {
    selected : 'ptBr',
    top : {
        phoneBr: '+55 (11) 95367 0683 Brazil',
        phoneUs: '+1 (407) 668-2897 USA'
    },
    menu : {
        bemobile: 'BE MOBILE',
        services: 'SERVIÇOS',
        cases: 'CASOS DE SUCESSO',
        contactus: 'FALE CONOSCO'
    },
    header : {
        logoBack: 'aproveitando esta conexão!'
    },
    general : {
        maisInfos: '+ infos',
        fechar: 'fechar',
        voltar: 'voltar',
        prox: 'próx',
        ant: 'ant',
    },
    cases : 
        [{
            name: 'Pcount',
            line1: 'React Native App',
            line2: 'creative design',
            frontend: true,
            backend: true,
            android: true,
            ios: false,
            imgCaroussel: 'pcount0.png',
            images: [
                {img: 'pcount1.png'},
                {img: 'pcount2.png'},
                {img: 'pcount3.png'},
            ],
            description: 'Desenvolvido para automação industrial, o aplicativo PCount foi criado para iniciar e finalizar processos em maquinários de chão de fábrica, utilizando leitura de QRCODE e recolhimento de assinatura como tecnologias.'
        },
         {
            name: 'Vyttra',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: true,
            imgCaroussel: 'vyttra0.png' 
        },
        {
            id: 'hectare',
            name: 'Hectare',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: true,
            imgCaroussel: 'hectare0.png' 
        },
        {
            name: 'Quem Tem Remédio?',
            line1: 'React Native App',
            line2: 'creative design, planning, programming',
            frontend: true,
            backend: true,
            android: true,
            ios: true,
            imgCaroussel: 'qtr0.png' 
        },
        {
            name: 'Ogma Okto',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: false,
            imgCaroussel: 'okto0.png' 
        },
        {
            name: '4Real',
            line1: 'React Native App',
            line2: 'creative design, programming',
            frontend: true,
            backend: true,
            android: true,
            ios: true,
            imgCaroussel: '4real0.png' 
        }
    ],
    concept: {
        description: "Bem-vindo à BeMobile, uma inovadora fábrica de software que está redefinindo o cenário dos aplicativos para celular. Com sedes em São Paulo, Brasil, e em Tampa, Florida, nossa empresa nasce da fusão de mentes criativas e estratégicas do Brasil e dos Estados Unidos. Estamos comprometidos em oferecer soluções de software de última geração, combinando a experiência em desenvolvimento técnico com um pensamento estratégico afiado. Nossa equipe multicultural traz uma abordagem única para cada projeto, aproveitando a diversidade de perspectivas e habilidades para criar aplicativos móveis de alto desempenho e impacto. Na BeMobile, entendemos que cada aplicativo tem o potencial de transformar a maneira como as pessoas interagem com a tecnologia no dia a dia. É por isso que nos dedicamos a superar as expectativas de nossos clientes, desde startups ambiciosas até empresas estabelecidas. Nossa abordagem centrada no cliente garante que cada etapa do processo de desenvolvimento seja cuidadosamente adaptada às necessidades e visão exclusiva de cada cliente. Estamos comprometidos em oferecer não apenas um produto final excepcional, mas uma parceria verdadeira e colaborativa que promova o sucesso a longo prazo. Com uma equipe de especialistas apaixonados e multidisciplinares, estamos prontos para enfrentar os desafios mais complexos e transformar ideias em realidade. Na BeMobile, não apenas criamos aplicativos - construímos experiências digitais memoráveis que inspiram, envolvem e conectam pessoas em todo o mundo. Junte-se a nós nesta jornada de inovação e descubra como podemos transformar sua visão em um aplicativo móvel de sucesso."
    }
}

export const enUs = {
    selected : 'enUs',
    top : { 
        phoneBr: '+55 (11) 95367 0683 Brazil',
        phoneUs: '+1 (407) 668-2897 USA'
    },
    menu : {
        bemobile: 'BE MOBILE',
        services: 'SERVICES',
        cases: 'CASES',
        contactus: 'CONTACT US'
    },
    header : {
        logoBack: 'enjoy the connection!'
    },
    general : {
        maisInfos: '+ infos',
        fechar: 'close',
        voltar: 'back',
        prox: 'next',
        ant: 'prev',
        tecnologia: 'Technology',
        oquefizemos: "What we're done"
    },
    cases : 
        [{
            name: 'Pcount',
            line1: 'React Native App',
            line2: 'creative design',
            frontend: true,
            backend: true,
            android: true,
            ios: false,
            imgCaroussel: 'pcount0.png',
            images: [
                {img: 'pcount1.png'},
                {img: 'pcount2.png'},
            ],   
            description: 'Developed for industrial automation, the PCount application was created to initiate and finalize processes on factory floor machinery, utilizing QR code scanning and signature collection as technologies.' 
        },
        {
            name: 'Quem Tem Remédio?',
            line1: 'React Native App',
            line2: 'creative design, planning, programming',
            frontend: true,
            backend: true,
            android: true,
            ios: true,
            imgCaroussel: 'qtr0.png',
            images: [
                {img: 'qtr1.png'},
                {img: 'qtr2.png'},
                {img: 'qtr3.png'},
            ],
            description: ''
        },
        {
            name: 'Vyttra Proxima DX',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: true,
            imgCaroussel: 'vyttra0.png', 
            images: [
                {img: 'vyttra2.png'},
                {img: 'vyttra3.png'},
                {img: 'vyttra4.png'},
                {img: 'vyttra5.png'},
                {img: 'vyttra6.png'},
            ],
            description: ''   
        },
        {
            id: 'hectare',
            name: 'Hectare',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: true,
            imgCaroussel: 'hectare0.png', 
            images: [
                {img: 'hectare1.png'},
                {img: 'hectare2.png'},
                {img: 'hectare3.png'},
                {img: 'hectare4.png'},
                {img: 'hectare5.png'},
            ],
            description: 'Application aimed at agribusiness, assisting agronomists in registering their environmental inspections. It utilizes geopositioning, image gallery, and document upload features.' 
        },{
            name: 'Ogma Okto',
            line1: 'React Native App',
            line2: 'programming',
            frontend: true,
            backend: false,
            android: true,
            ios: false,
            imgCaroussel: 'okto0.png',
            images: [
                {img: 'okto1.png'},
                {img: 'okto2.png'}
            ],
            description: ''
        },
        {
            name: '4Real',
            line1: 'React Native App',
            line2: 'creative design, programming',
            frontend: true,
            backend: true,
            android: true,
            ios: true,
            imgCaroussel: '4real0.png',
            images: [
                {img: '4real1.png'},
                {img: '4real2.png'},
            ],
            description: ''
        }
    ],
    concept: {
        description: "Welcome to BeMobile, an innovative software factory that is redefining the mobile app landscape. With headquarters in São Paulo, Brazil, and Tampa, Florida, our company stems from the merger of creative and strategic minds from Brazil and the United States. We are committed to delivering cutting-edge software solutions by combining technical development expertise with sharp strategic thinking. Our multicultural team brings a unique approach to each project, leveraging the diversity of perspectives and skills to create high-performance, impactful mobile apps. At BeMobile, we understand that each app has the potential to transform how people interact with technology on a daily basis. That's why we dedicate ourselves to exceeding the expectations of our clients, from ambitious startups to established enterprises. Our customer-centric approach ensures that each stage of the development process is carefully tailored to the unique needs and vision of every client. We are committed to offering not only an exceptional end product but also a true collaborative partnership that fosters long-term success. With a team of passionate and multidisciplinary experts, we are ready to tackle the most complex challenges and turn ideas into reality. At BeMobile, we don't just create apps - we build memorable digital experiences that inspire, engage, and connect people worldwide. Join us on this journey of innovation and discover how we can turn your vision into a successful mobile app."
    }
}