import React, { useState } from "react";
import Slider from "react-slick";
import './Cases.css'

import icoCelularzinho1 from '../assets/icoCelularzinhoOn.png'
import icoCelularzinho2 from '../assets/icoCelularzinhoOff.png'
import Cases from "./Cases";

function CasesSlider(props) {
    const images = props.images
    const [active, setActive] = useState(1)

  const settings = {
    dots: true,
    dotsClass: "slick-dots-custom slick-thumb",
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: function(i) {
        return (
          <a onMouseEnter={()=>{setActive(i+1)}} class="dots"> 
            
          </a>
        );
      },
  };

  return (
    <div style={{width: 950, height: 850}}>
      <Slider {...settings}>
        {(images)?.map((img, index)=>(
            
            <div key={index}>
                <img src={require('../assets/portfolio/'+img.img)} key={index} />
            </div>
        ))}
      </Slider>
    </div>
  );
}

export default CasesSlider
