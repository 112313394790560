import React, {useState, useContext, useEffect} from "react";
import { ptBr, enUs } from "../Contexts/LocaleContext";
import LocaleContext from "../Contexts/Contexts";
import setaLocale from '../assets/setaLocale.png'

import '../App.css'

const Top = () => {
    const [selLocale, setSelLocale] = useContext(LocaleContext)
    const locale = selLocale.top

    useEffect(()=>{
            setSelLocale(enUs)
    }, [])
    
  return(
    
        <div className="Top">
            <div id="top">
                <div style={{color: '#2f2f2f', fontSize: 15, padding: 10}}>
                    <span style={{marginRight: 40}}>{locale.phoneBr}</span>{locale.phoneUs}  
                </div>
            </div>
            {selLocale.selected === 'enUs' ?            
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <button onClick={()=>{setSelLocale(ptBr)}} className="buttonTop">
                        português
                    </button>   
                    <button className="buttonTopAlive">
                        english
                        <img src={setaLocale} style={{marginTop:4}} />
                    </button>
                </div>
            :
                <div style={{display: 'flex', flexDirection:'row'}}>
                    <button className="buttonTopAlive">
                        português
                        <img src={setaLocale} style={{marginTop:4}} />
                    </button>
                    <button onClick={()=>{setSelLocale(enUs)}} className="buttonTop">
                        english
                    </button>   
                </div>
            }
        </div>
    
  )
}
export default Top