import React, { useContext, useEffect, useRef, useState } from "react";
import '../App.css'
import LocaleContext from "../Contexts/Contexts";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import SimpleImageSlider from "react-simple-image-slider";

import CasesSlider from "./CasesSlider";
import './Cases.css'

import worksdone from '../assets/worksdone.png'
import android from '../assets/android.png'
import ios from '../assets/ios.png'
import androidRoxo from '../assets/androidRoxo.png'
import iosRoxo from '../assets/iosRoxo.png'
import icoCelularzinhoOn from '../assets/icoCelularzinhoOn.png'
import icoCelularzinhoOff from '../assets/icoCelularzinhoOff.png'
import icoBackPortfolio from '../assets/icoBackPortfolio.png'

const Cases = () => {
    const [selLocale, setSelLocale] = useContext(LocaleContext)
    const general = selLocale.general
    const projetos = selLocale.cases
    const [imagens, setImagens] = useState([])
    const [projeto, setProjeto] = useState([])
    const [showPortfolio, setShowPortfolio] = useState(false)
    const swiperSlide = useSwiperSlide()


    function AbreProjeto(name, images, description, android, ios, frontend, backend, line1, line2) {
        setProjeto({'nome': name, 'images': images, 'description': description, 'android': android, 'ios': ios, 'frontend': frontend, 'backend': backend, 'line1': line1, 'line2': line2})
        setImagens(images)
        setShowPortfolio(true)
    }

    const Portfolio = () => {
        
        return(
            
            <div data-aos='flip-left' id="cases" class='item' style={{position: "absolute", zIndex: 303, top: 0, left: 0, width: '100%', height: 600, backgroundColor: '#000000a0', display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'space-between'}}>
                    <button onClick={()=>{setShowPortfolio(false)}} style={{backgroundColor: 'transparent', border: 'none', width: 180, marginTop: -320}} ><img src={icoBackPortfolio}/></button>
                     
                    <div style={{ height: 565, width:1530 , display: 'flex', flexDirection:'row'}} class='scroll-behavior: smooth;'>
                        <CasesSlider images={projeto.images} />
                        
                    </div>
                    <div style={{ display: 'flex', alignItems: 'flex-start', height: 400, flexDirection: 'column', justifyContent:'space-between'}}>
                        <div style={{width: 250, paddingLeft: 20,}}>
                            <span style={{color: '#ff00c5', fontSize: 25}}>{projeto.nome}</span><br />
                            {projeto?.description}<br/><br/>
                            <span style={{color: '#ff00c5'}}>{general.tecnologia}</span><br />
                            {projeto.line1}<br/><br/>
                            <span style={{color: '#ff00c5'}}>{general.oquefizemos}</span><br />
                            {projeto.line2}<br/><br/>
                            <span style={{color: '#ff00c5'}}>DEV</span><br />
                            {projeto.frontend ? 'frontend' : null}{projeto.frontend && projeto.backend ? ' / ' : null}{projeto.backend ? 'backend' : null}
                        </div>
                        <div style={{width:250, paddingLeft: 20, display: 'flex', alignItems:'center'}}>
                            {projeto.android ? <img src={androidRoxo} style={{marginRight: 10}} /> : null}
                            {projeto.ios ? <img src={iosRoxo} style={{marginRight: 10}} /> : null }
                        </div>    
                    </div>

                        
            </div> 
        )
    }

    return(
        <div className="Cases" style={{height:800, display: 'flex', flexDirection: 'column'}}>
            <div className="worksdone">
                <img src={worksdone} />
            </div>
            <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 222,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination]}
        initialSlide={3}
        loop={true}
      >
        {showPortfolio ? <Portfolio /> : null }
        
        {projetos?.map((proj, index)=>(
         <div>
            <div style={{position: "absolute", zIndex: 303, top: 0, left: 0, width: '100%', height: 600, backgroundColor: '#ffb900'}}>
                <img src={icoBackPortfolio} />
                <div>

                </div>
                <div>
                    <span style={{color: '#ff00c5'}}>{proj.name}</span>
                </div>
            </div>  
            <SwiperSlide> 
            <div>
                <button onClick={()=>{AbreProjeto(proj.name, proj.images, proj.description, proj.android, proj.ios, proj.frontend, proj.backend, proj.line1, proj.line2)}} class='botaoMais'>
                    <div class='botaoMaisRedondo'>+</div>
                </button>
                <img src={require('../assets/portfolio/'+proj.imgCaroussel)} />
                <div className="mapTextos">
                    <div className="mapTxt">
                        
                        <span style={{color: '#ffffff', fontSize: 25, lineHeight: 1.7}}>{proj.name}</span><br />
                        <span style={{color: '#999999', fontSize: 18, lineHeight: 1.3}}>{proj.line1}</span><br />
                        <span style={{color: '#999999', fontSize: 18, lineHeight: 1.3}}>{proj.line2}</span><br />
                        <span style={{color: '#999999', fontSize: 18, lineHeight: 1.3}}>{proj.frontend ? 'frontEnd' : null}{proj.frontend && proj.backend ? ' / ' : null}{proj.backend ? 'backEnd' : null}</span><br />
                        
                    </div>
                </div>
                
                <div style={{position:'absolute', zIndex: 10, bottom: 20, right: 20, display:'flex' ,flexDirection: 'row', alignItems: 'baseline'}}>
                    {proj.android ? <img src={android} style={{width: 38, height: 27, marginLeft: 15, marginBottom: 2}} /> : null }
                    {proj.ios ? <img src={ios} style={{width: 33, height: 34, marginLeft: 15}} /> : null}
                </div>  
            </div>  
            </SwiperSlide>
        </div>
        ))}
      </Swiper>
    </>
        </div>
    )
    
}
export default Cases