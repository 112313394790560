import React, {useState, useEffect} from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Top from './ComponentesHome/Top';
import Header from './ComponentesHome/Header';
import Concept from './ComponentesHome/Concept';
import MobileDevelopment from './ComponentesHome/MobileDevelopment';
import Cases from './ComponentesHome/Cases';
import Contact from './ComponentesHome/Contact';
import Bottom from './ComponentesHome/Bottom';
import LocaleContext from './Contexts/Contexts';
import { ptBr, enUs } from './Contexts/LocaleContext';

function App() {
  const [selLocale, setSelLocale] = useState(enUs)

  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <LocaleContext.Provider value={[selLocale, setSelLocale]}>
      <div className="App">
        <div className='Content'>
          <Top />
          <div data-aos='fade-up'><Header /></div>
          <Concept />
          <MobileDevelopment />
          <div data-aos='fade-up'><Cases /></div>
          <Contact />
          <Bottom />
        </div>
      </div>
    </LocaleContext.Provider>
  );
}

export default App;
