import React from "react";
import '../App.css'

const Bottom = () => {

    return(
        <div className="Bottom">
            Bottom
            <div id='bottom'></div>
        </div>
    )
}
export default Bottom