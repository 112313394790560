import React from "react";
import '../App.css'

const Contact = () => {

    return(
        <div className="Contact">
            Contact
        </div>
    )
}
export default Contact