import React from "react";
import '../App.css'
import Menu from "./Menu";

const MobileDevelopment = () => {

    return(
        <>
        <div className="MobileDevelopment">
            
                Mobile Development
                
        </div>
        </>
    )
}
export default MobileDevelopment