import React, { useContext } from "react";
import '../App.css'
import LocaleContext from "../Contexts/Contexts";
import Logo from "./Logo";
import marker from '../assets/marker.png'

const Header = () => {
    const [selLocale, setSelLocale] = useContext(LocaleContext)
    const locale = selLocale.header

    return(
        <div className="Header">
            <div data-aos='fade-down-left' className="markerTampa">
                <img src={marker} />
                <div style={{backgroundColor: '#4d4d4d', width: 20, height: 2, marginLeft: 10, marginRight: 10}}></div>
                <span style={{color: '#4d4d4d'}}>Tampa - FL</span>
            </div>
            <div data-aos='fade-down-right' className="markerSP">
                <img src={marker} />
                <div style={{backgroundColor: '#4d4d4d', width: 20, height: 2, marginLeft: 10, marginRight: 10}}></div>
                <span style={{color: '#4d4d4d'}}>São Paulo - SP</span>
            </div>
            <div data-aos='flip-left'>
                <Logo logoBack={locale.logoBack} />
            </div>
        </div>
    )
}
export default Header