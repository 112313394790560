import React, { useContext } from "react";
import '../App.css'
import LocaleContext from "../Contexts/Contexts";

const Menu = () => {
    const [selLocale, setSelLocale] = useContext(LocaleContext)

    const locale = selLocale.menu

    return(
        <>
        <div className="Menu">
            <a href={window.location.href = "#bemobile"} className="botoMenu">{locale.bemobile}</a>
            <a href={window.location.href = "#services"} className="botoMenu">{locale.services}</a>
            <a href={window.location.href = "#cases"} className="botoMenu">{locale.cases}</a>
            <a href={window.location.href = "#contactus"} className="botoMenu">{locale.contactus}</a>
        </div>
        </>
    )
}
export default Menu