import React, { useContext, useEffect } from "react";
import '../App.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Menu from "./Menu";
import LocaleContext from "../Contexts/Contexts";
import conceptTitleEng from '../assets/conceptTitleEng.png'
import conceptTitlePt from '../assets/conceptTitlePt.png'
import conceptFrameRight from '../assets/frameBeMobileRight.png'


const Concept = () => {
    const [selLocale, setSelLocale] = useContext(LocaleContext)

    const locale = selLocale.concept

    useEffect(() => {
        AOS.init();
      }, [])

    return(
        <>
            
            <div className="Concept">
            <Menu />
            <div style={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 700}}>
                <div data-aos='flip-left'>
                    <img src={selLocale.selected === 'enUs' ? conceptTitleEng : conceptTitlePt} />
                    
                </div>
                <div data-aos='flip-right' style={{width: 563, height: 431, backgroundImage: `url(${conceptFrameRight})` }}>
                    <div className="scroll">
                    {locale.description}
                    </div>
                </div>
            </div>            
            </div>
        </>
    )
}
export default Concept