import React from "react";
import './logo.css'
import logoNome from '../assets/logoBemobile.png'
import wifi from '../assets/logoWifi.png'

const Logo = (props) => {
    const phrase = props.logoBack

    return(
        <>
        <div className="card" style={{marginLeft: 100}}>
        <div className='BoxProjeto'>
            <div class="content">
                <div class="front">
                    <div className="bg"></div>
                    <div className='logo'>
                        <img src={logoNome} />
                    </div> 

                    <p class="wifi">
                        <img src={wifi} />
                    </p>
                </div>

                <div class="camera">
                    <div style={{backgroundColor: '#d000a2', width: 6, height: 6, borderRadius: 50}}></div>
                </div>
                <div class="back">

                    <div className="textoBack">
                        {phrase}
                    </div>
                    <p class="wifi">
                        <img src={wifi} />
                    </p>
                </div>
            </div>
        </div>
        </div>  
        </>
    )
}

export default Logo